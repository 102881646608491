import React, { useState } from "react"
import { useForm, Controller } from "react-hook-form"
import { Container, Form, Row, Col, Button } from "react-bootstrap"
import DatePicker from "react-datepicker"

import "react-datepicker/dist/react-datepicker.css"

const PortablePoolSignupForm = () => {
  const { register, control, watch, reset, formState } = useForm({
    mode: "onChange",
    reValidateMode: "onBlur",
  })
  const { isValid, touchedFields, errors } = formState

  const [succeeded, setSucceeded] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [startDate, setStartDate] = useState(new Date())

  const watchData = watch()
  // console.log(watchData)

  //   const data = new FormData(document.forms["swim-lessons"])

  //   const formJSON = Object.fromEntries(data.entries());
  //         formJSON.lesson_days = data.getAll('lesson_days');

  const handleSubmit = async ev => {
    // Gatsby check to make sure document is defined
    let data = null
    if (typeof document !== undefined) {
      data = document.forms["swim-lessons"]

      //console.log(FormData(data))
    }
    ev.preventDefault()
    setProcessing(true)

    fetch(
      `https://50h7z499yg.execute-api.us-west-2.amazonaws.com/production/tva-swim-lessons-registration`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(watchData),
      }
    )
      .then(response => {
        if (response.status === 200) {
          console.log(JSON.stringify(watchData))
        } else {
          console.log("network error")
        }
      })
      .then(
        fetch(`https://hooks.zapier.com/hooks/catch/7272839/bbhl2uz/`, {
          method: "POST",
          body: JSON.stringify(watchData),
        })
          .then(response => {
            if (response.status === 200) {
              setSucceeded(true)
              reset()
              console.log("Data submitted to Zapier!")
            } else {
              console.log("Error")
            }
          })
          .catch(error => console.log("error!", error.message))
      )
    setTimeout(data => {}, 5000)
  }

  const SuccessMessage = () => (
    <Container>
      <Row className="my-4">
        <Col>
        <h4 as="h4" w="100%" style={{ textAlign: "center" }}>
        Thank you for submitting your application. <br />We'll be in touch on June 25th!
      </h4>
        </Col>
      </Row>

    </Container>
  )

  return (
    <>
      {succeeded ? (
        <SuccessMessage />
      ) : (
        <>
          <p>
            Thank you for your interest in Teton Valley Aquatics Portable Pool
            Swim Lessons program. Lessons will be held twice a week for 4 weeks.
          </p>
          <p>
            Our priority in this program is to serve children who are
            non-swimmers or currently swimming at American Red Cross Level 1 and
            2 proficiency levels. Children will be selected to participate on a
            randomized basis, with priority given to those who are non-swimmers
            or need financial assistance. Please complete one form for each child. Children under 4 years old can participate
            in the Parent &amp; Me classes only.
          </p>
          <p>
            <strong>
              Program Location: TVA Portable Pool - Driggs City Park
            </strong>
          </p>
          <p>
            <strong>Program Dates: 4- Week Sessions</strong>
            <br />
            <strong>Session 1:</strong> Starting the week of July 5th and ending
            the week of July 26th.
            <br />
            <strong>Session 2:</strong> Starting the week of August 2nd and
            ending the week of August 23rd
          </p>
          <p>
            <strong>Lesson Days:</strong>
            <br />
            Mondays &amp; Wednesdays
            <br />
            Tuesdays &amp; Thursdays
            <br />
            Saturdays Only
          </p>
          <p>
            <strong>Program Fees:</strong> Our mission at Teton Valley Aquatics
            is to improve the health and wellness of our community through the
            power of water. In order to achieve this, we will be offering swim
            lessons in our portable pool on a "by donation" basis. Lessons will
            cost Teton Valley Aquatics an average of $65/swimmer to cover the
            cost of instruction, safety, and maintenance. If you are able to
            assist in covering these costs, please choose to make a donation to
            TVA when registering for lessons. In order to ensure these lessons
            are accessible to all, TVA received a grant from the Community
            Foundation of Teton Valley to remove financial barriers so all
            children can benefit from these lessons.
          </p>
          <p>
          <strong>Day of Info:</strong> There are no changing/locker rooms at the portable pool, please make sure your child is dressed and ready to swim upon arrival at the Driggs City Park. There are porta potties available for bathroom breaks. Parents will be required to stay at the Driggs City Park during the entirety of their child's swim lesson. 
          </p>
          <p>
            Please contact Stacy Stamm at{" "}
            <a href="mailto:info@tetonvalleyaquatics.org">
              info@tetonvalleyaquatics.org
            </a>{" "}
            with any questions.
          </p>
          <hr />
          <h4>Registration</h4>
          <form onSubmit={handleSubmit} name="swim-lessons" id="swim-lessons">
            <Row className="mb-4">
              <Col md={6}>
                <Form.Group>
                  <Form.Label>
                    Parent/Guardian's Name<span>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    {...register("parent_name", { required: true })}
                  />
                  {errors.parent_name && (
                    <p style={{ color: "red" }}>Please enter your name.</p>
                  )}
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group>
                  <Form.Label>
                    Parent/Guardian's Email<span>*</span>
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder=""
                    {...register("parent_email", {
                      required: true,
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: "Enter a valid e-mail address",
                      },
                    })}
                  />
                  {errors.parent_email && (
                    <p style={{ color: "red" }}>
                      Please enter a valid email address.
                    </p>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={8}>
                <Form.Group>
                  <Form.Label>
                    Parent/Guardian's Mailing Address<span>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    {...register("mailing_address", { required: true })}
                  />
                  {errors.mailing_address && (
                    <p style={{ color: "red" }}>
                      Please enter your mailing address.
                    </p>
                  )}
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group>
                  <Form.Label>
                    City<span>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    {...register("mailing_city", { required: true })}
                  />
                  {errors.mailing_city && (
                    <p style={{ color: "red" }}>Please enter your city.</p>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Form.Group>
                  <Form.Label>
                    State<span>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    {...register("mailing_state", { required: true })}
                  />
                  {errors.mailing_state && (
                    <p style={{ color: "red" }}>Please enter your state.</p>
                  )}
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group>
                  <Form.Label>
                    Zip Code<span>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    {...register("mailing_zip", { required: true })}
                  />
                  {errors.mailing_zip && (
                    <p style={{ color: "red" }}>Please enter your zip code.</p>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-4">
              <Col md={8}>
                <Form.Group>
                  <Form.Label>
                    Parent/Guardian's Phone Number<span>*</span>
                  </Form.Label>
                  <Form.Control
                    type="phone"
                    placeholder="208-555-5555"
                    {...register("parent_phone", {
                      required: true,
                      pattern: {
                        value: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
                        message: "Please enter a valid phone number",
                      },
                    })}
                  />
                  {errors.parent_phone && (
                    <p style={{ color: "red" }}>
                      Please enter a valid phone number
                    </p>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>
                    Emergency Contact's Name<span>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    {...register("emergency_name", { required: true })}
                  />
                  {errors.mailing_zip && (
                    <p style={{ color: "red" }}>Please enter a name.</p>
                  )}
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group>
                  <Form.Label>
                    Emergency Contact's Relationship<span>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    {...register("emergency_relationship", { required: true })}
                  />
                  {errors.mailing_zip && (
                    <p style={{ color: "red" }}>
                      Please enter the relationship.
                    </p>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-4">
              <Col md={8}>
                <Form.Group>
                  <Form.Label>
                    Emergency Contact's Phone Number<span>*</span>
                  </Form.Label>
                  <Form.Control
                    type="phone"
                    placeholder="208-555-5555"
                    {...register("emergency_phone", {
                      required: true,
                      pattern: {
                        value: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
                        message: "Please enter a valid phone number",
                      },
                    })}
                  />
                  {errors.emergency_phone && (
                    <p style={{ color: "red" }}>
                      Please enter a valid phone number.
                    </p>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={8}>
                <Form.Group>
                  <Form.Label>
                    Child's Name<span>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    {...register("child_name", { required: true })}
                  />
                  {errors.child_name && (
                    <p style={{ color: "red" }}>
                      Please enter the child's name.
                    </p>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col md={8}>
                <Form.Group>
                  <Form.Label>
                    Child's Gender<span>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    {...register("child_gender", { required: true })}
                  />
                  {errors.child_gender && (
                    <p style={{ color: "red" }}>
                      Please enter the child's name.
                    </p>
                  )}
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group>
                  <Form.Label>
                    Child's Birthdate<span>*</span>
                  </Form.Label>
                  <Controller
                    name="child_birthdate"
                    control={control}
                    defaultValue={new Date()}
                    render={({ field }) => (
                      <DatePicker
                        onChange={e => field.onChange(e)}
                        selected={field.value}
                        placeholderText="Child's Birthdate"
                        dateFormat="MM/dd/yyyy"
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        className="form-control"
                      />
                    )}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <h5>Child's Current Swim Level</h5>
                <p>
                  <strong>Non-Swimmer</strong> - My child does not know how to
                  swim. Requires 100% assistance when in the water and cannot do
                  anything unsupported. May demonstrate a fear of water.
                </p>
                <p>
                  <strong>Level 1</strong> - My child can enter and exit the
                  water without assistance, walk in the water; move along the
                  gutter, fully submerge face in the water and blow bubbles,
                  kick on front or back with or without support, front and back
                  float with or without support.
                </p>
                <p>
                  <strong>Level 2</strong> - My child can open eyes underwater
                  and retrieve underwater objects. Front and back float without
                  support. Flutter kick on front and back. Swim short distances
                  in deeper water. Demonstrate bobbing. Demonstrate rotary
                  breathing. Jump into the water and recover. Tread water using
                  arm and leg actions.
                </p>
              </Col>
            </Row>

            <Row className="mb-4">
              <Col>
                <Form.Group className="my-2">
                  <Form.Check
                    className="radio"
                    type="radio"
                    label="Non-Swimmer"
                    name="swim_level"
                    id="non_swimmer"
                    value="Non-Swimmer"
                    {...register("swim_level", { required: true })}
                  />
                  <Form.Check
                    className="radio"
                    type="radio"
                    label="Level 1"
                    name="swim_level"
                    id="level_1"
                    value="Level 1"
                    {...register("swim_level", { required: true })}
                  />
                  <Form.Check
                    className="radio"
                    type="radio"
                    label="Level 2"
                    name="swim_level"
                    id="level_2"
                    value="Level 2"
                    {...register("swim_level", { required: true })}
                  />
                  <Form.Check
                    className="radio"
                    type="radio"
                    label="Other"
                    name="swim_level"
                    id="other"
                    value="Other"
                    {...register("swim_level", { required: true })}
                  />
                  {watchData.swim_level === "Other" && (
                    <>
                      <Form.Control
                        type="text"
                        placeholder="Please describe"
                        {...register("other_swim_level")}
                      />
                    </>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-4">
              <Col>
                <p>
                  Preferred lesson days. Please select days that will work for
                  you.
                </p>
                <Form.Group>
                  <Form.Check
                    type="checkbox"
                    label="Monday/Wednesday"
                    name="lesson_days"
                    id="mon-wed"
                    value="Monday/Wednesday"
                    {...register("lesson_days")}
                  />
                  <Form.Check
                    type="checkbox"
                    label="Tuesday/Thursday"
                    name="lesson_days"
                    id="tue-thu"
                    value="Tuesday/Thursday"
                    {...register("lesson_days")}
                  />
                  <Form.Check
                    type="checkbox"
                    label="Saturday Only (mornings only)"
                    name="lesson_days"
                    id="sat"
                    value="Saturday"
                    {...register("lesson_days")}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-4">
              <Col>
                <p>
                  Preferred lesson time. Please select at least TWO time slots
                </p>
                <Form.Group>
                  <Form.Check
                    type="checkbox"
                    label="9:30-10:00 am"
                    name="lesson_days"
                    id="time1"
                    value="9:30-10:00 am"
                    {...register("lesson_times")}
                  />
                  <Form.Check
                    type="checkbox"
                    label="10:00-10:30 am"
                    name="lesson_days"
                    id="time2"
                    value="10:00-10:30 am"
                    {...register("lesson_times")}
                  />
                  <Form.Check
                    type="checkbox"
                    label="10:30-11:00 am"
                    name="lesson_days"
                    id="time3"
                    value="10:30-11:00 am"
                    {...register("lesson_times")}
                  />
                  <Form.Check
                    type="checkbox"
                    label="11:00-11:30 am"
                    name="lesson_days"
                    id="time4"
                    value="11:00-11:30 am"
                    {...register("lesson_times")}
                  />
                  <Form.Check
                    type="checkbox"
                    label="11:30-12:00 pm"
                    name="lesson_days"
                    id="time5"
                    value="11:30-12:00 pm"
                    {...register("lesson_times")}
                  />
                  <Form.Check
                    type="checkbox"
                    label="4:30-5:00 pm"
                    name="lesson_days"
                    id="time6"
                    value="4:30-5:00 pm"
                    {...register("lesson_times")}
                  />
                  <Form.Check
                    type="checkbox"
                    label="5:00-5:30 pm"
                    name="lesson_days"
                    id="time7"
                    value="5:00-5:30 pm"
                    {...register("lesson_times")}
                  />
                  <Form.Check
                    type="checkbox"
                    label="5:30-6:00 pm"
                    name="lesson_days"
                    id="time8"
                    value="5:30-6:00 pm"
                    {...register("lesson_times")}
                  />
                  <Form.Check
                    type="checkbox"
                    label="6:00-6:30 pm"
                    name="lesson_days"
                    id="time9"
                    value="6:00-6:30 pm"
                    {...register("lesson_times")}
                  />
                  <Form.Check
                    type="checkbox"
                    label="6:30-7:00 pm"
                    name="lesson_days"
                    id="time10"
                    value="6:30-7:00 pm"
                    {...register("lesson_times")}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-4">
              <Col>
                <p>
                Which session are you applying for?
                </p>
                <Form.Group>
                  <Form.Check
                    type="radio"
                    label="July Session (Week of July 5th-July 26th"
                    name="session"
                    id="july"
                    value="July Session"
                    {...register("session")}
                  />
                  <Form.Check
                    type="radio"
                    label="August Session (Week of August 2nd-August 23rd)"
                    name="session"
                    id="august"
                    value="August Session"
                    {...register("session")}
                  />
                  <Form.Check
                    type="radio"
                    label="Both Sessions"
                    name="session"
                    id="both"
                    value="Both Sessions"
                    {...register("session")}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-4">
              <Col>
                <p>
                  Swim lessons will be provided by donation only, with an
                  average of $65/swimmer needed to cover the cost of providing
                  lessons. Will you be able to make a donation to assist with
                  providing lessons for our community?
                </p>
                <p>Your response to this question will have no bearing on your child's application for swim lessons and is not a formal commitment to make a donation</p>
                <Form.Group>
                  <Form.Check
                    type="radio"
                    label="I am unable to make a donation at this time."
                    name="donation"
                    id="unable"
                    value="Unable to donate"
                    {...register("donation")}
                  />
                  <Form.Check
                    type="radio"
                    label="I am able to make a donation of less than $65."
                    name="donation"
                    id="less_than_65"
                    value="Less than $65"
                    {...register("donation")}
                  />
                  <Form.Check
                    type="radio"
                    label="I am able to make a donation of $65 to cover the cost of my child's lesson."
                    name="donation"
                    id="donate_65"
                    value="Donate $65"
                    {...register("donation")}
                  />
                  <Form.Check
                    type="radio"
                    label="I am able to make a donation of greater than $65 to cover my child's lesson and support other children as well."
                    name="donation"
                    id="more_than_65"
                    value="More than $65"
                    {...register("donation")}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-4">
              <Col>
                <p>
                  Does your child participate or is eligible to participate in
                  the free and reduced lunch program at school?
                </p>
                <Form.Group>
                  <Form.Check
                    type="radio"
                    label="Yes"
                    name="lunch"
                    id="yes_lunch"
                    value="Yes"
                    {...register("lunch")}
                  />
                  <Form.Check
                    type="radio"
                    label="No"
                    name="lunch"
                    id="no_lunch"
                    value="No"
                    {...register("lunch")}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-4">
              <Col>
                <p>
                  To participate in the program, a swimsuit and towel is
                  necessary. TVA can provide assistance in purchasing these
                  items.
                </p>
                <Form.Group>
                  <Form.Check
                    type="radio"
                    label="Yes, I would like support in purchasing at swimsuit and towel for my child."
                    name="swimsuit"
                    id="yes_swimsuit"
                    value="Yes"
                    {...register("swimsuit")}
                  />
                  <Form.Check
                    type="radio"
                    label="No, I do not need support."
                    name="swimsuit"
                    id="no_swimsuit"
                    value="No"
                    {...register("swimsuit")}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-4">
              <Col>
                <Form.Group>
                  <Form.Label>
                    Is there anything else you'd like to share with us?
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder="Comments..."
                    {...register("comments")}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Button type="submit" mt={4} disabled={!isValid}>
              {processing ? "Submitting..." : "Submit"}
            </Button>
          </form>
        </>
      )}
    </>
  )
}

export default PortablePoolSignupForm
